import React, { Component } from "react"
import ReactMapGL, { Marker } from "react-map-gl"
import { FaMapMarker } from "react-icons/fa"

class Map extends Component {
  state = {
    viewport: {
      width: "100%",
      height: this.props.height,
      latitude: 27.427008386266404,
      longitude: -82.54004844817808,
      zoom: 12,
    },
    loading: true,
  }

  componentDidMount = () => {
    this.setState({
      viewport: {
        width: this.props.width,
        height: this.props.height,
        latitude: lat,
        longitude: lng,
        zoom: 12,
      },
      loading: false,
    })
  }

  render() {
    if (this.state.loading) {
      return null
    }

    return (
      <div>
        <ReactMapGL
          {...this.state.viewport}
          onViewportChange={viewport => this.setState({ viewport })}
          mapboxApiAccessToken={`pk.eyJ1IjoibHRjcGciLCJhIjoiY2tobDA4c3huMTNpajJ3cHRpd2ZmNnRpMSJ9.YB_32J-PJb6wWfCSYEEslQ`}
          mapStyle={"mapbox://styles/mapbox/streets-v11"}
          attributionControl={false}
          scrollZoom={false}
        >
          <Marker
            latitude={lat}
            longitude={lng}
            offsetLeft={-20}
            offsetTop={-10}
          >
            <a href="https://goo.gl/maps/rupHKUKMaD3K32Xm6" target="_blank">
              <FaMapMarker style={{ color: "#255eae" }} size={45} />
            </a>
          </Marker>
        </ReactMapGL>
        {/*
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.props.lat},${this.props.lng}&zoom=13&size=600x300&maptype=roadmap&key=AIzaSyCKTH9-keXatEkkyry9R5S0tA6DPGFyqpE`}
              alt=""
            />
          */}
      </div>
    )
  }
}

export default Map

Map.defaultProps = {
  lat: 27.427008386266404,
  lng: -82.54004844817808,
  width: "100%",
  height: 600,
}

const lat = 27.427008386266404
const lng = -82.54004844817808
