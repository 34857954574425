import React from "react"

import colors from "../colors"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PersonList from "../components/person/PersonList"
import Map from "../components/map"
import Blockquote from "../components/blockquote"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkBlue,
}

const About = () => {
  return (
    <PageLayout>
      <SEO
        title="About ArtVue Glass | Serving Florida"
        ogDescription=""
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>ABOUT US</h1>
            <p>
              ArtVue Glass is a leading manufacturer of decorative interior glass for commercial and residential applications.
            </p>
            <p>
Our goal is to provide decorative glass products to our customers that exceed their quality and service expectations. We will do this by using our 25 plus years of experience and fully understand the needs of our customers.
            </p>
            <p>
              The company prides itself in putting customers first.  Since each customer has its own requirements, the ArtVue Glass team takes time with each customer to fully understand their unique requirements, communicate the entire process up front, answer any questions, and work with customers to provide a distinctive look for any space using custom glass and mirror.
            </p>
            <p>
              ArtVue Glass stocks a wide variety of glass and mirror to choose from.  They also manufacture finished products locally which means faster turnaround times, shorter lead times, higher on-time delivery and less shipping costs.
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/about/artvue-employees.jpg"
              alt="Employees - ArtVue Glass"
            />
            <Blockquote>
              Part of the challenge – and the excitement – is that no two customer orders are the same.
            </Blockquote>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/about/artview-glass-owners.png"
              alt="John and Karen - ArtVue Glass"
            />
          </div>
        }
        textRight={
          <div>
            <h2>
              ArtVue Glass Origins
            </h2>
            <p>
              John Tagle came from the glass and glass fabrication industry, where he had work in various roles for many years. The 2008-09 financial crisis impacted glass fabricators especially hard, and John was having difficulty finding high quality decorative glass for his customers in the Southeast. Most of the suppliers were in other parts of the country; manufacturing and shipping lead times were long; and it trying to finalize custom designs was a challenge.  
            </p>
            <p>
              John and his wife, Karen, would discuss these frustrations in the evenings.  One night in late 2014 as John and Karen were talking abut it, they decided to start their own company.  That is when the idea for ArtVue Glass was created.  With years of experience, know-how, and a keen sense for delighting customers, ArtVue Glass was formed.
            </p>
            <h2>
              Evolution of the Company
            </h2>
            <p>
              At first, ArtVue Glass started in a small shop in Bradenton, FL offering wholesale sheets of pattern glass with some cut sizes for cabinets and displays. As the company grew, so did their product offering. More pattern glass options were continually added, along with antique mirrors. 
The next phase included offering back painted glass manufactured in house, as a spray booth and curing oven are required.  After the successful launch of their back painted glass, laminated safety glass was next.  The ability to laminate decorative glass including decorative interlayers and most recently SentryGlas structural interlayers was a game-changer.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              Today and Tomorrow
            </h2>
            <p>
              Today, ArtVue Glass has grown to serve thousands of commercial and residential customers, domestically and internationally.
            </p>
            <p>
              ArtVue Glass’ philosophy is centered on quality products, customer satisfaction, and education.
            </p>

          </div>
        }
        textRight={
          <div>
            <img
              src="/images/about/artvue-top-glass-fabricator.jpg"
              alt="Top Glass Fabricator - ArtVue Glass"
            />
          </div>
        }
      />
      <PageWrapper maxWidth color="grey">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Reach Out To Us</h2>
              <p>
  Customers call us all the time and ask, ‘Can this be done with glass?’ We spend the time to answer questions and explain the many ways glass and mirror can be effectively used in their projects.
              </p>
              <p>
                Interested in how ArtVue Glass can help on your next project, give us a call.
              </p>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
            </div>
          }
        />
      </PageWrapper>
      <Map />
    </PageLayout>
  )
}

export default About
