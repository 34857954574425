import React from "react"
import Img from "gatsby-image"
import "./index.scss"

const Person = ({ name, role, bio, image }) => {
  return (
    <div className="person">
      <div>
        {image && (
          <div className="image-col">
            <img className="box-image" src={`/images/${image}`} alt={name} />
          </div>
        )}
        <div className="content-col">
          <h2>{name}</h2>
          <span className="role">{role}</span>
        </div>
      </div>
      <div className="bio-col">
        <div
          key={`body`}
          className="content"
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: bio }}
        />
      </div>
    </div>
  )
}

export default Person

Person.defaultProps = {
  image: "",
  name: "",
  role: "",
  bio: "",
}
